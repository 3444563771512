@use "../../utils/functions/" as *;
@use "../../utils/mixins/" as *;

.elevating {
  &__content {
    margin: 0 auto rem(60);
    width: 100%;
    max-width: 656px;
    text-align: center;
  }

  &__swiper {
    position: relative;
    overflow: hidden;
  }

  &__nav {
    .btn {
      position: absolute;
      top: 35%;
      transform: translateY(-50%);

      &.swiper-button-disabled {
        opacity: 0.2;
        pointer-events: none;
      }

      &--prev {
        left: 30px;
        scale: -1 1;
      }

      &--next {
        left: auto;
        right: 30px;
      }
    }
  }

  @include media(tablet) {
    &__content {
      margin-bottom: 30px;
    }
  }
}
