@use "../utils/functions/" as *;
@use "../utils/mixins/" as *;

.filter {
  $root: &;

  &__desc {
    margin-bottom: 30px;
  }

  &__nav {
    display: flex;
    align-items: center;
    margin-bottom: 30px;
    gap: 10px;
  }

  &__grid {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 30px 24px;
  }

  &__content {
    &--portfolio {
      img {
        min-height: 170px;
      }
    }
  }

  &__text {
    position: absolute;
    z-index: 1;
    bottom: 0;
    padding: 26px;
    color: var(--color-white);

    h3 {
      margin-bottom: 6px;
      color: var(--color-white);
      letter-spacing: 0.01em;
    }

    p {
      margin-bottom: 14px;
      opacity: 0.7;
    }

    a {
      font-size: 18px;
      text-decoration: underline;
      text-decoration-color: transparent;
      transition: text-decoration-color 0.4s linear;

      &:hover {
        text-decoration-color: currentcolor;
      }
    }
  }

  &--portfolio {
    #{$root}__grid {
      grid-template-columns: 1fr;
      margin-bottom: 0;
    }

    #{$root}__content {
      position: relative;
      z-index: 1;

      &::before {
        content: "";
        position: absolute;
        z-index: 1;
        background: linear-gradient(180deg, rgba(21, 20, 17, 0) 35.378%, rgb(21, 20, 17) 137.5%);
        inset: 0;
      }
    }
  }

  @include media(tablet-wide) {
    &__nav {
      max-width: 100%;
      justify-content: flex-start;
    }
  }

  @include media(tablet) {
    &__nav {
      margin-bottom: 30px;
    }

    &__desc {
      margin-bottom: 25px;
    }
  }

  @include media(mobile-l) {
    &__grid {
      grid-template-columns: repeat(2, 1fr);
    }
  }

  @include media(mobile-m) {
    &__grid {
      grid-template-columns: 1fr;
    }
  }
}
