@use "../../utils/functions/" as *;
@use "../../utils/mixins/" as *;

.portfolio {
  &__wrapper {
    display: grid;
    align-items: flex-end;
    grid-template-columns: repeat(2, 1fr);
    gap: 20px;
  }

  &__content {
    margin-bottom: rem(60);
    width: 100%;
    max-width: 580px;
  }

  &__list {
    display: grid;
    justify-content: flex-end;
    grid-template-columns: repeat(3, auto);
    margin-bottom: rem(60);
    gap: 52px;
  }

  &__item {
    span {
      display: block;
      margin-bottom: 10px;
      font-weight: var(--fw-700);
      font-size: clamp(2rem, 1.75rem + 1.1111vi, 2.75rem);
      line-height: 1.3;
      color: var(--color-black);
    }
  }

  h1 {
    margin-bottom: rem(60);
  }

  @include media(tablet) {
    &__wrapper {
      grid-template-columns: 1fr;
    }

    &__content {
      margin-bottom: 30px;
      max-width: 100%;
    }

    &__list {
      justify-content: center;
      margin-bottom: 30px;
      gap: 20px;
    }

    img {
      width: 100%;
      aspect-ratio: 2/1;
    }

    h1 {
      margin-bottom: 30px;
    }
  }

  @include media(mobile-l) {
    &__item {
      text-align: center;
    }
  }
}
