@use "../utils/functions/" as *;
@use "../utils/mixins" as *;

.policy {
  &__content {
    &:not(:last-child) {
      margin-bottom: 24px;
    }
  }

  h1 {
    margin-bottom: rem(60);
    font-size: clamp(2.25rem, 2.0833rem + 0.7407vi, 2.75rem);
  }

  h2 {
    margin-bottom: 10px;
    font-size: 24px;
  }

  p,
  li {
    line-height: 1.6;
  }

  ul {
    margin-bottom: 20px;
    padding-left: 15px;
  }

  li {
    padding-left: 10px;
    color: var(--color-grey-400);

    &:not(:last-child) {
      margin-bottom: 3px;
    }

    &::marker {
      content: "✓";
      color: var(--color-orange-400);
    }
  }

  p {
    &:not(:last-child) {
      margin-bottom: 20px;
    }
  }

  a {
    text-decoration: underline;
    color: var(--color-orange-400);
  }

  @include media(tablet) {
    h1 {
      margin-bottom: 30px;
    }
  }
}
