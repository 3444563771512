@use "../utils/functions/" as *;
@use "../utils/mixins" as *;

.news {
  &__content {
    margin: 0 auto rem(50);
    text-align: center;

    p {
      width: 100%;
      max-width: 849px;
      text-align: center;
      margin-inline: auto;
    }
  }

  &__image {
    &:not(:last-child) {
      margin-bottom: rem(50);
    }
  }

  &__wrapper {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 30px;

    p {
      text-align: justify;

      &:not(:last-child) {
        margin-bottom: 32px;
      }
    }
  }

  h1 {
    margin-bottom: 20px;
  }

  h2 {
    margin-bottom: 12px;
    font-size: 24px;
  }

  @include media(mobile-l) {
    &__wrapper {
      grid-template-columns: 1fr;
      margin-bottom: 16px;
    }

    &__content {
      margin-bottom: 30px;
    }

    &__image {
      &:not(:last-child) {
        margin-bottom: 30px;
      }
    }

    img {
      width: 100%;
      aspect-ratio: 2/1;
    }

    p {
      &:not(:last-child) {
        margin-bottom: 16px;
      }
    }
  }
}
