@use "../utils/functions/" as *;
@use "../utils/mixins" as *;

.product {
  &__inner {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 24px;
  }

  &__content {
    width: 100%;
    max-width: 610px;
  }

  &__list {
    margin-bottom: 20px;

    li {
      display: flex;
      flex-direction: column;
      font-size: 18px;
    }
  }

  &__price {
    font-weight: var(--fw-700);
    font-size: 44px;
    color: var(--color-black);

    sup {
      font-size: 29px;
    }
  }

  h1,
  p {
    margin-bottom: 20px;
  }

  h1 {
    font-size: clamp(1.875rem, 1.5833rem + 1.2963vi, 2.75rem);
  }

  img {
    aspect-ratio: 1/1;
  }

  @include media(tablet) {
    &__inner {
      flex-direction: column-reverse;
    }

    &__list {
      column-count: 2;
      width: fit-content;
      gap: 50px;
      margin-inline: auto;
    }

    &__content {
      max-width: 100%;
    }

    &__price {
      display: block;
      text-align: center;
    }

    img {
      width: 100%;
      object-fit: contain;
      aspect-ratio: 2/1;
    }
  }
}
