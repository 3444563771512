@use "../utils/functions/" as *;
@use "../utils/mixins/" as *;

.slider {
  padding: rem(60) 0;

  &__content {
    margin: 0 auto rem(50);
    width: 100%;
    max-width: 595px;
    text-align: center;
  }

  &__swiper {
    position: relative;
    overflow: hidden;
  }

  &__item {
    position: relative;
    height: auto;
  }

  &__list {
    padding-bottom: 250px;
  }

  &__desc {
    position: absolute;
    left: 0;
    right: 0;
    z-index: 1;
    margin: 0 auto;
    padding: 40px;
    width: 100%;
    max-width: 1080px;
    box-shadow: 0 4px 100px 0 rgba(175, 173, 181, 0.1);
    background-color: var(--color-white);
    translate: 0 -139px;
  }

  &__nav {
    .btn {
      position: absolute;
      top: 28%;
      transform: translateY(-50%);

      &.swiper-button-disabled {
        opacity: 0.2;
        pointer-events: none;
      }

      &--prev {
        left: 30px;
        scale: -1 1;
      }

      &--next {
        left: auto;
        right: 30px;
      }
    }
  }

  h1 {
    margin-bottom: 20px;
  }

  h2 {
    margin-bottom: 16px;
    font-size: 26px;
  }

  span {
    display: block;
    margin-bottom: 10px;
  }

  @include media(tablet) {
    padding: 30px 0;

    &__content {
      margin-bottom: 30px;
    }

    &__desc {
      padding: 16px;
    }

    &__list {
      padding-bottom: 30px;
    }
  }

  @include media(tablet) {
    &__list {
      padding-bottom: 15px;
    }

    &__desc {
      position: relative;
      max-width: 100%;
      inset: 0;
      translate: 0;
    }

    img {
      margin-bottom: 24px;
    }
  }

  @include media(mobile-l) {
    .btn {
      top: 15%;
    }
  }

  @include media(mobile-m) {
    .btn {
      top: 10%;
    }
  }
}
