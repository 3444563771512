/* stylelint-disable declaration-no-important */
/* stylelint-disable declaration-block-no-duplicate-properties */

@use "../utils/functions/"as*;
@use "../utils/mixins/"as*;

.hidden {
  opacity: 0;
  
}

.sr-only {
  position: absolute !important;
  -webkit-clip-path: inset(50%) !important;
  clip-path: inset(50%) !important;
  overflow: hidden !important;
  margin: -1px !important;
  border: 0 !important;
  padding: 0 !important;
  width: 1px !important;
  height: 1px !important;
  white-space: nowrap !important;
  clip: rect(1px, 1px, 1px, 1px) !important;
}

.dis-scroll {
  position: fixed;
  left: 0;
  top: 0;
  overflow: hidden;
  width: 100%;
  height: 100dvh;
  height: calc(var(--vh, 1vh) * 100);
  overscroll-behavior: none;
}

.js-focus-visible:focus:not(.focus-visible) {
  outline: none;
}