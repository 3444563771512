@use "../utils/functions/" as *;
@use "../utils/mixins" as *;

.cards {
  &__content {
    padding-right: 55px;
  }

  h3 {
    margin-bottom: 6px;
    font-size: clamp(1.125rem, 0.9583rem + 0.7407vi, 1.625rem);
    letter-spacing: 0.01em;
  }

  p {
    margin-bottom: 18px;
    line-height: 1.8;
  }

  span {
    display: block;
    margin-bottom: 14px;
    font-weight: var(--fw-700);
    font-size: 18px;
    line-height: 1.3;
  }

  img {
    margin-bottom: 26px;
    width: 100%;
  }

  @include media(tablet) {
    &__content {
      padding-right: 0;
    }
  }
}
