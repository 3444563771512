@use "../utils/functions/" as *;
@use "../utils/mixins" as *;

.article {
  position: relative;
  display: flex;
  flex-direction: column;
  height: 100%;

  &__content {
    display: flex;
    flex-direction: column;
    padding-right: 40px;
    height: 100%;
  }

  &__categories {
    margin-bottom: 12px;
  }

  &__price {
    font-weight: var(--fw-700);
    font-size: 24px;
    line-height: 1.3;
    color: var(--color-black);

    sup {
      font-size: 18px;
    }
  }

  h3 {
    margin-bottom: 6px;
    font-size: clamp(1.125rem, 0.9583rem + 0.7407vi, 1.625rem);
    transition: color 0.3s linear;
  }

  p {
    margin-bottom: 14px;
    line-height: 1.8;
  }

  a {
    &::before {
      content: "";
      position: absolute;
      inset: 0;
    }
  }

  img {
    margin-bottom: 26px;
  }

  &:hover {
    h3 {
      color: var(--color-green-400);
    }
  }

  @include media(tablet) {
    &__content {
      padding: 16px;
      height: fit-content;
    }

    h3 {
      min-height: 35px;
      max-width: 100%;
    }
  }

  @include media(mobile-l) {
    img {
      margin-bottom: 16px;
      width: 100%;
      object-fit: contain;
      aspect-ratio: 2/1;
    }
  }
}
