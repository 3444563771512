@import "https://fonts.googleapis.com/css2?family=Raleway:ital,wght@0,100..900;1,100..900&display=swap";
:root {
  --content-width: 1240px;
  --container-offset: 16px;
  --container-width: calc(var(--content-width)  + (var(--container-offset) * 2));
  --font-family-primary: "Raleway", sans-serif;
  --fw-300: 300;
  --fw-400: 400;
  --fw-500: 500;
  --fw-600: 600;
  --fw-700: 700;
  --fw-800: 800;
  --color-white: #fff;
  --color-black: #151411;
  --color-grey-10: #f9f9f9;
  --color-grey-200: #f5f5f5;
  --color-grey-400: #afadb5;
  --color-green-50: #e6ffe4;
  --color-green-400: #518581;
  --color-green-200: #71c285;
  --color-green-900: #043a2a;
  --color-orange-400: #ffb23f;
  --color-red-400: #802f34;
  --color-blue-400: #b0d3f0;
  --radius-main: 8px;
}

.checkbox__field:checked + .checkbox__content:after {
  opacity: 1;
}

.checkbox__field:focus + .checkbox__content:before {
  outline: 1px solid var(--color-gray-100);
  outline-offset: 1px;
}

.checkbox__field:disabled + .checkbox__content {
  opacity: .4;
  pointer-events: none;
}

html, body {
  scroll-behavior: smooth;
  overscroll-behavior-y: none;
}

.page {
  height: var(--vh);
  font-family: var(--font-family-primary);
  font-weight: var(--fw-500);
  letter-spacing: 0;
  color: var(--color-grey-400);
  background-color: var(--color-white);
  font-optical-sizing: auto;
  scroll-padding: var(--header-height);
  font-size: clamp(16px, 1vw, 20px);
  font-style: normal;
  line-height: 1.8;
}

.page__body {
  min-width: 320px;
  min-height: var(--vh);
  position: relative;
}

.page__body:before {
  content: "";
  z-index: -1;
  opacity: 0;
  background-color: #27282bbf;
  transition: opacity .3s;
  position: absolute;
  inset: 0;
}

.page.open {
  overflow: hidden;
}

.page.open > .page__body:before {
  z-index: 5;
  opacity: 1;
}

.section {
  padding: 3.75rem 0;
}

@media only screen and (max-width: 992px) {
  .section {
    padding: 1.875rem 0;
  }
}

.container {
  padding: 0 var(--container-offset);
  width: 100%;
  max-width: var(--container-width);
  margin: 0 auto;
}

.site-container {
  min-height: var(--vh);
  grid-template-rows: 1fr auto;
  grid-template-columns: 100%;
  display: grid;
}

.main {
  padding-top: calc(var(--header-height)  - 1px);
}

.logo {
  width: 100%;
  max-width: 281px;
}

@media only screen and (max-width: 1180px) {
  .logo {
    max-width: 220px;
  }
}

.is-scrolling {
  scrollbar-color: var(--color-green-400) var(--color-white);
  scrollbar-width: thin;
  overflow-y: scroll;
}

.is-scrolling::-webkit-scrollbar {
  width: 4px;
  height: 4px;
}

.is-scrolling::-webkit-scrollbar-track {
  background-color: var(--color-white);
  border-radius: 10px;
}

.is-scrolling::-webkit-scrollbar-thumb {
  background-color: var(--color-green-400);
  border-radius: 10px;
}

@media only screen and (max-width: 1180px) {
  .is-scrolling {
    padding: 15px;
    overflow-y: scroll;
  }
}

.hidden {
  opacity: 0;
}

.sr-only {
  -webkit-clip-path: inset(50%) !important;
  clip-path: inset(50%) !important;
  width: 1px !important;
  height: 1px !important;
  white-space: nowrap !important;
  clip: rect(1px, 1px, 1px, 1px) !important;
  border: 0 !important;
  margin: -1px !important;
  padding: 0 !important;
  position: absolute !important;
  overflow: hidden !important;
}

.dis-scroll {
  width: 100%;
  height: 100dvh;
  height: calc(var(--vh, 1vh) * 100);
  overscroll-behavior: none;
  position: fixed;
  top: 0;
  left: 0;
  overflow: hidden;
}

.js-focus-visible:focus:not(.focus-visible) {
  outline: none;
}

h1, h2, h3 {
  font-weight: var(--fw-700);
  color: var(--color-black);
  font-size: clamp(2.125rem, 1.5rem + 2.7778vi, 4rem);
  line-height: 1.3;
  overflow: hidden;
}

h2 {
  margin-bottom: 30px;
  font-size: clamp(1.875rem, 1.5833rem + 1.2963vi, 2.75rem);
}

h3 {
  font-size: clamp(1.125rem, .9583rem + .7407vi, 1.625rem);
}

p {
  font-size: 18px;
}

@media only screen and (max-width: 576px) {
  p {
    font-size: 16px;
  }
}

.slogan {
  font-weight: var(--fw-700);
  color: var(--color-orange-400);
  margin-bottom: 14px;
  font-size: 18px;
  line-height: 1.3;
  display: block;
}

.header {
  z-index: 10;
  width: 100%;
  background-color: var(--color-white);
  padding: 33px 0;
  transition-property: transform;
  transition-duration: .15s;
  position: fixed;
  top: 0;
}

.header:before {
  content: "";
  width: 100%;
  height: 1px;
  background-color: #ece4de;
  position: absolute;
  bottom: 0;
}

.header.hide {
  transform: translateY(-200%);
}

.header .nav__link {
  justify-content: center;
  align-items: center;
  display: flex;
}

@media only screen and (max-width: 992px) {
  .header {
    padding: 16px 0;
  }

  .header .nav__inner {
    z-index: -1;
    padding-top: calc(var(--header-height) * 1.6);
    width: 100%;
    height: var(--vh);
    max-width: 350px;
    background-color: var(--color-white);
    flex-direction: column;
    justify-content: flex-start;
    transition-property: transform;
    transition-duration: .4s;
    position: fixed;
    top: 0;
    right: 0;
    overflow-y: auto;
    transform: translateX(150%);
  }

  .header .nav__list {
    z-index: -1;
    flex-direction: column;
  }

  .header .nav__item {
    margin-bottom: 30px;
  }

  .header .nav__item:not(:last-child) {
    margin-right: 0;
  }

  .header .nav__link:before {
    bottom: -15px;
  }

  .header .nav__btns {
    flex-direction: column;
  }
}

.nav {
  justify-content: space-between;
  align-items: center;
  display: flex;
  position: relative;
}

.nav__inner {
  width: 100%;
  align-items: center;
  display: flex;
}

.nav__list {
  align-items: center;
  margin: 0 auto;
  display: flex;
}

.nav__item:not(:last-of-type) {
  margin-right: 50px;
}

@media only screen and (max-width: 1180px) {
  .nav__item:not(:last-of-type) {
    margin-right: 25px;
  }
}

.nav__link {
  color: var(--color-black);
  background-color: #0000;
  font-size: 18px;
  line-height: 1.33;
  transition: text-shadow .3s linear;
  position: relative;
}

.nav__link:before {
  content: "";
  width: 75px;
  height: 3px;
  background-color: var(--color-green-400);
  opacity: 0;
  position: absolute;
  bottom: -48px;
}

.nav__link:hover {
  text-shadow: .3px .3px;
}

.nav__link.true:before {
  opacity: 1;
}

.nav__btns {
  align-items: center;
  gap: 14px;
  display: flex;
}

.nav--footer .nav__list {
  margin: 0;
}

@media only screen and (max-width: 576px) {
  .nav--footer .nav__item {
    margin: 0 0 16px;
  }
}

@media only screen and (max-width: 992px) {
  .nav--footer {
    flex-direction: column;
    gap: 24px;
  }
}

@media only screen and (max-width: 576px) {
  .nav--footer .nav__list {
    flex-direction: column;
  }
}

@media only screen and (max-width: 475px) {
  .nav--footer .nav__btns {
    flex-direction: column;
  }
}

[class].btn {
  width: 100%;
  height: max-content;
  max-width: -moz-fit-content;
  max-width: fit-content;
  font-weight: var(--fw-700);
  color: var(--color-white);
  background-color: var(--color-green-400);
  border: 2px solid #0000;
  border-radius: 0;
  justify-content: center;
  align-items: center;
  padding: 13px 50px;
  font-size: 18px;
  line-height: 1.3;
  transition-property: background-color, color, border-color;
  transition-duration: .3s;
  display: flex;
}

[class].btn:hover, [class].btn:focus {
  border-color: var(--color-green-400);
  color: var(--color-green-400);
  background-color: var(--color-white);
}

[class].btn.formsapp-button {
  margin: 0;
  font-family: var(--font-family-primary) !important;
}

[class].btn--reverse {
  border-color: var(--color-green-400);
  color: var(--color-green-400);
  background-color: var(--color-white);
}

[class].btn--reverse:hover, [class].btn--reverse:focus {
  color: var(--color-white);
  background-color: var(--color-green-400);
}

[class].btn--filter {
  white-space: nowrap;
  color: var(--color-grey-400);
  background-color: #0000;
  border-color: #0000;
  padding: 15px 20px;
}

[class].btn--filter:hover, [class].btn--filter:focus {
  color: var(--color-black);
  background-color: var(--color-grey-10);
  border-color: #0000;
}

[class].btn--filter.active {
  color: var(--color-black);
  background-color: var(--color-grey-10);
  border-color: #0000;
  box-shadow: 0 4px 120px #afadb526;
}

[class].btn--request {
  color: var(--color-white) !important;
  background-color: var(--color-green-400) !important;
}

[class].btn--request:hover, [class].btn--request:focus {
  border-color: var(--color-green-400) !important;
  color: var(--color-green-400) !important;
  background-color: var(--color-white) !important;
}

[class].btn--elevating, [class].btn--slider {
  z-index: 2;
  height: 52px;
  max-width: 52px;
  -webkit-backdrop-filter: blur(15px);
  backdrop-filter: blur(15px);
  background-color: #15141199;
  border-color: #0000;
  border-radius: 50%;
  padding: 10px;
  position: relative;
}

[class].btn--elevating:before, [class].btn--slider:before {
  content: "";
  width: 24px;
  height: 24px;
  background-image: url("../images/icons/chevron.svg");
  background-position: 0 0;
  background-repeat: no-repeat;
  background-size: cover;
  position: absolute;
}

[class].btn--elevating:hover, [class].btn--elevating:focus, [class].btn--slider:hover, [class].btn--slider:focus {
  background-color: #0000;
  border-color: #0000;
}

[class].btn--elevating:hover:before, [class].btn--elevating:focus:before, [class].btn--slider:hover:before, [class].btn--slider:focus:before {
  opacity: 1;
}

@media only screen and (max-width: 768px) {
  [class].btn--elevating, [class].btn--slider {
    height: 42px;
    max-width: 42px;
  }
}

[class].btn--inspiring {
  background-color: var(--color-green-400);
  padding: 32px;
  box-shadow: 0 4px 90px #afadb533;
}

[class].btn--inspiring:before {
  content: "";
  width: 28px;
  height: 28px;
  background-color: currentColor;
  background-color: var(--color-white);
  transition: background-color .4s linear;
  position: absolute;
  -webkit-mask-image: url("../images/icons/arrow.svg");
  mask-image: url("../images/icons/arrow.svg");
  -webkit-mask-position: center;
  mask-position: center;
  -webkit-mask-size: cover;
  mask-size: cover;
  -webkit-mask-repeat: no-repeat;
  mask-repeat: no-repeat;
}

[class].btn--inspiring:hover, [class].btn--inspiring:focus {
  background-color: var(--color-white);
}

[class].btn--inspiring:hover:before, [class].btn--inspiring:focus:before {
  background-color: var(--color-green-400);
}

@media only screen and (max-width: 768px) {
  [class].btn--inspiring {
    padding: 20px;
  }
}

.burger {
  --line-height: 2px;
  --burger-size: 20px;
  --move: calc(var(--burger-size) / 3);
  --move-decrement: calc(var(--move) * -1);
  --transition-time: .5s;
  --lines-color: var(--color-green-400);
  width: calc(var(--burger-size)  + 5px);
  height: var(--burger-size);
  transition: opacity var(--transition-time) ease;
  background-color: #0000;
  display: none;
  position: absolute;
  top: 50%;
  right: 0;
  transform: translateY(-50%);
}

.burger span {
  z-index: -1;
  width: 100%;
  height: var(--line-height);
  background-color: var(--lines-color);
  transition: transform var(--transition-time) ease, background-color var(--transition-time) ease .3s;
  display: block;
  position: absolute;
  top: 50%;
  left: 0;
  transform: translateY(-50%);
}

.burger span:before, .burger span:after {
  content: "";
  width: 100%;
  height: var(--line-height);
  background-color: var(--lines-color);
  animation-duration: .4s;
  animation-timing-function: cubic-bezier(.645, .045, .355, 1);
  animation-fill-mode: forwards;
  position: absolute;
  left: 0;
}

.burger span:before {
  transform: translateY(var(--move-decrement)) rotate(0);
}

.burger span:after {
  transform: translateY(var(--move)) rotate(0);
}

.burger:hover {
  opacity: 1;
}

@media only screen and (max-width: 992px) {
  .burger {
    display: block;
  }
}

.closed.opened span {
  background-color: var(--lines-color);
  transition: background-color .2s;
}

.closed.opened span:before {
  animation-name: toplineburger;
}

.closed.opened span:after {
  animation-name: bottomlineburger;
}

.opened .nav__inner {
  transform: translateX(0);
}

.opened span {
  background-color: #0000;
  transition: background-color .2s;
}

.opened span:before, .opened span:after {
  animation-duration: .2s;
  animation-timing-function: cubic-bezier(.645, .045, .355, 1);
  animation-fill-mode: forwards;
}

.opened span:before {
  animation-name: toplinecross;
}

.opened span:after {
  animation-name: bottomlinecross;
}

@keyframes toplinecross {
  0% {
    transform: translateY(var(--move-decrement));
  }

  50% {
    transform: translateY(0);
  }

  100% {
    transform: rotate(45deg);
  }
}

@keyframes bottomlinecross {
  0% {
    transform: translateY(var(--move));
  }

  50% {
    transform: translateY(0);
  }

  100% {
    transform: translateY(0)rotate(-45deg);
  }
}

@keyframes toplineburger {
  0% {
    transform: rotate(45deg);
  }

  50% {
    transform: rotate(0);
  }

  100% {
    transform: translateY(var(--move-decrement)) rotate(0);
  }
}

@keyframes bottomlineburger {
  0% {
    transform: translateY(0)rotate(-45deg);
  }

  50% {
    transform: rotate(0);
  }

  100% {
    transform: translateY(var(--move)) rotate(0);
  }
}

.policy__content:not(:last-child) {
  margin-bottom: 24px;
}

.policy h1 {
  margin-bottom: 3.75rem;
  font-size: clamp(2.25rem, 2.0833rem + .7407vi, 2.75rem);
}

.policy h2 {
  margin-bottom: 10px;
  font-size: 24px;
}

.policy p, .policy li {
  line-height: 1.6;
}

.policy ul {
  margin-bottom: 20px;
  padding-left: 15px;
}

.policy li {
  color: var(--color-grey-400);
  padding-left: 10px;
}

.policy li:not(:last-child) {
  margin-bottom: 3px;
}

.policy li::marker {
  content: "✓";
  color: var(--color-orange-400);
}

.policy p:not(:last-child) {
  margin-bottom: 20px;
}

.policy a {
  color: var(--color-orange-400);
  text-decoration: underline;
}

@media only screen and (max-width: 992px) {
  .policy h1 {
    margin-bottom: 30px;
  }
}

.footer__top {
  background-color: var(--color-grey-200);
  border-bottom: 1px solid #e6ffe4;
  padding: 3.6875rem 0;
}

.footer__bottom {
  color: var(--color-black);
  background-color: var(--color-white);
  justify-content: space-between;
  padding: 19px 0;
  font-size: 20px;
  display: flex;
}

.footer__bottom p {
  letter-spacing: 0;
  font-size: 20px;
  line-height: 1.6;
}

.footer__list {
  gap: 40px;
  display: flex;
}

.footer__item {
  transition: text-shadow .3s linear;
}

.footer__item:hover {
  text-shadow: .4px .4px;
}

@media only screen and (max-width: 992px) {
  .footer__list {
    gap: 20px;
  }
}

@media only screen and (max-width: 768px) {
  .footer__top {
    padding: 30px 0;
  }

  .footer__bottom {
    text-align: center;
    flex-direction: column;
    align-items: center;
    gap: 20px;
  }
}

.contacts__wrapper {
  position: relative;
}

.contacts__content {
  width: 100%;
  max-width: 600px;
  margin: 0 auto;
  position: absolute;
  top: 30px;
  left: 0;
  right: 0;
}

.contacts__form {
  width: 100%;
  gap: 24px;
}

.contacts__form label, .contacts__form textarea {
  width: 100%;
}

.contacts__form input, .contacts__form textarea {
  width: 100%;
  max-width: 100%;
  background-color: var(--color-white);
  border: 2px solid #ece4de;
  padding: 9px 0 9px 24px;
}

.contacts__form input::placeholder, .contacts__form textarea::placeholder {
  color: var(--color-grey-400);
  font-size: 18px;
  line-height: 1.44;
}

.contacts__form textarea {
  min-height: 110px;
  resize: none;
}

.contacts__inputs {
  grid-template-columns: repeat(2, 1fr);
  gap: 10px;
  margin-bottom: 20px;
  display: grid;
}

.contacts__box {
  display: grid;
}

.contacts h2 {
  letter-spacing: -.01em;
  text-align: center;
  color: var(--color-white);
  font-size: clamp(1.875rem, 1.625rem + 1.1111vi, 2.625rem);
  line-height: 1.24;
}

.contacts .btn {
  margin: 0 auto;
}

@media only screen and (max-width: 768px) {
  .contacts__wrapper {
    flex-direction: column-reverse;
    gap: 25px;
    display: flex;
  }

  .contacts__content {
    position: relative;
    inset: 0;
  }

  .contacts h2 {
    color: var(--color-black);
  }
}

@media only screen and (max-width: 576px) {
  .contacts__inputs {
    grid-template-columns: 1fr;
    gap: 20px;
  }

  .contacts__inputs .btn {
    max-width: 100%;
  }

  .contacts__box {
    gap: 20px;
  }
}

@media only screen and (max-width: 375px) {
  .contacts {
    padding-top: 10px;
  }
}

.hero__inner {
  text-align: center;
  flex-direction: column;
  align-items: center;
  display: flex;
}

.hero__image img {
  width: 100%;
}

.hero__content {
  width: 100%;
  max-width: 820px;
  text-align: center;
  margin: 0 auto 3.75rem;
}

.hero__btn {
  width: -moz-fit-content;
  width: fit-content;
  background-color: var(--color-white);
  padding: 0 29px 29px;
  position: absolute;
  left: -29px;
  translate: 0 31px;
}

.hero h1 {
  margin-bottom: 30px;
}

.hero h1 span {
  color: var(--color-red-400);
}

.hero h1 span:first-of-type {
  color: var(--color-green-200);
}

.hero h1 span:last-of-type {
  color: var(--color-blue-400);
}

.hero--home .hero__content {
  position: relative;
}

.hero--home .hero__content:before {
  content: "";
  width: 130px;
  height: 318px;
  background-image: url("../images/icons/big-arrow.svg");
  background-position: 0 0;
  background-repeat: no-repeat;
  background-size: contain;
  position: absolute;
  bottom: -10px;
  left: -145px;
}

.hero--home p {
  font-size: 20px;
}

.hero--catalog .hero__content {
  max-width: 595px;
  margin-bottom: 3.125rem;
}

.hero--catalog h1 {
  margin-bottom: 20px;
}

.hero--services {
  padding: 3.75rem 0 7.5rem;
}

.hero--services .hero__content {
  max-width: 100%;
}

.hero--services .hero__content h1 {
  margin-bottom: 21px;
}

.hero--services .hero__content p {
  max-width: 505px;
  margin: 0 auto;
}

.hero--services .hero__image {
  position: relative;
}

.hero--services .hero__image:before {
  content: "";
  height: 2px;
  width: 100%;
  background-color: var(--color-grey-200);
  position: absolute;
  bottom: -60px;
}

@media only screen and (max-width: 1180px) {
  .hero--home .hero__content:before {
    height: 50%;
    left: -1%;
  }
}

@media only screen and (max-width: 992px) {
  .hero__content {
    margin-bottom: 30px;
  }

  .hero__btn {
    translate: 0 19px;
  }

  .hero--catalog .hero__content {
    margin-bottom: 30px;
  }

  .hero--services {
    padding: 30px 0;
  }

  .hero--services .hero__image:before {
    bottom: -30px;
  }
}

.filter__desc {
  margin-bottom: 30px;
}

.filter__nav {
  align-items: center;
  gap: 10px;
  margin-bottom: 30px;
  display: flex;
}

.filter__grid {
  grid-template-columns: repeat(3, 1fr);
  gap: 30px 24px;
  display: grid;
}

.filter__content--portfolio img {
  min-height: 170px;
}

.filter__text {
  z-index: 1;
  color: var(--color-white);
  padding: 26px;
  position: absolute;
  bottom: 0;
}

.filter__text h3 {
  color: var(--color-white);
  letter-spacing: .01em;
  margin-bottom: 6px;
}

.filter__text p {
  opacity: .7;
  margin-bottom: 14px;
}

.filter__text a {
  font-size: 18px;
  -webkit-text-decoration: underline #0000;
  text-decoration: underline #0000;
  transition: -webkit-text-decoration-color .4s linear, text-decoration-color .4s linear;
}

.filter__text a:hover {
  -webkit-text-decoration-color: currentColor;
  text-decoration-color: currentColor;
}

.filter--portfolio .filter__grid {
  grid-template-columns: 1fr;
  margin-bottom: 0;
}

.filter--portfolio .filter__content {
  z-index: 1;
  position: relative;
}

.filter--portfolio .filter__content:before {
  content: "";
  z-index: 1;
  background: linear-gradient(#15141100 35.378%, #151411 137.5%);
  position: absolute;
  inset: 0;
}

@media only screen and (max-width: 1180px) {
  .filter__nav {
    max-width: 100%;
    justify-content: flex-start;
  }
}

@media only screen and (max-width: 992px) {
  .filter__nav {
    margin-bottom: 30px;
  }

  .filter__desc {
    margin-bottom: 25px;
  }
}

@media only screen and (max-width: 768px) {
  .filter__grid {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media only screen and (max-width: 576px) {
  .filter__grid {
    grid-template-columns: 1fr;
  }
}

.article {
  height: 100%;
  flex-direction: column;
  display: flex;
  position: relative;
}

.article__content {
  height: 100%;
  flex-direction: column;
  padding-right: 40px;
  display: flex;
}

.article__categories {
  margin-bottom: 12px;
}

.article__price {
  font-weight: var(--fw-700);
  color: var(--color-black);
  font-size: 24px;
  line-height: 1.3;
}

.article__price sup {
  font-size: 18px;
}

.article h3 {
  margin-bottom: 6px;
  font-size: clamp(1.125rem, .9583rem + .7407vi, 1.625rem);
  transition: color .3s linear;
}

.article p {
  margin-bottom: 14px;
  line-height: 1.8;
}

.article a:before {
  content: "";
  position: absolute;
  inset: 0;
}

.article img {
  margin-bottom: 26px;
}

.article:hover h3 {
  color: var(--color-green-400);
}

@media only screen and (max-width: 992px) {
  .article__content {
    height: -moz-fit-content;
    height: fit-content;
    padding: 16px;
  }

  .article h3 {
    min-height: 35px;
    max-width: 100%;
  }
}

@media only screen and (max-width: 768px) {
  .article img {
    width: 100%;
    object-fit: contain;
    aspect-ratio: 2 / 1;
    margin-bottom: 16px;
  }
}

.product__inner {
  justify-content: space-between;
  align-items: center;
  gap: 24px;
  display: flex;
}

.product__content {
  width: 100%;
  max-width: 610px;
}

.product__list {
  margin-bottom: 20px;
}

.product__list li {
  flex-direction: column;
  font-size: 18px;
  display: flex;
}

.product__price {
  font-weight: var(--fw-700);
  color: var(--color-black);
  font-size: 44px;
}

.product__price sup {
  font-size: 29px;
}

.product h1, .product p {
  margin-bottom: 20px;
}

.product h1 {
  font-size: clamp(1.875rem, 1.5833rem + 1.2963vi, 2.75rem);
}

.product img {
  aspect-ratio: 1 / 1;
}

@media only screen and (max-width: 992px) {
  .product__inner {
    flex-direction: column-reverse;
  }

  .product__list {
    column-count: 2;
    width: -moz-fit-content;
    width: fit-content;
    gap: 50px;
    margin-inline: auto;
  }

  .product__content {
    max-width: 100%;
  }

  .product__price {
    text-align: center;
    display: block;
  }

  .product img {
    width: 100%;
    object-fit: contain;
    aspect-ratio: 2 / 1;
  }
}

.cards__content {
  padding-right: 55px;
}

.cards h3 {
  letter-spacing: .01em;
  margin-bottom: 6px;
  font-size: clamp(1.125rem, .9583rem + .7407vi, 1.625rem);
}

.cards p {
  margin-bottom: 18px;
  line-height: 1.8;
}

.cards span {
  font-weight: var(--fw-700);
  margin-bottom: 14px;
  font-size: 18px;
  line-height: 1.3;
  display: block;
}

.cards img {
  width: 100%;
  margin-bottom: 26px;
}

@media only screen and (max-width: 992px) {
  .cards__content {
    padding-right: 0;
  }
}

.news__content {
  text-align: center;
  margin: 0 auto 3.125rem;
}

.news__content p {
  width: 100%;
  max-width: 849px;
  text-align: center;
  margin-inline: auto;
}

.news__image:not(:last-child) {
  margin-bottom: 3.125rem;
}

.news__wrapper {
  grid-template-columns: repeat(2, 1fr);
  gap: 30px;
  display: grid;
}

.news__wrapper p {
  text-align: justify;
}

.news__wrapper p:not(:last-child) {
  margin-bottom: 32px;
}

.news h1 {
  margin-bottom: 20px;
}

.news h2 {
  margin-bottom: 12px;
  font-size: 24px;
}

@media only screen and (max-width: 768px) {
  .news__wrapper {
    grid-template-columns: 1fr;
    margin-bottom: 16px;
  }

  .news__content, .news__image:not(:last-child) {
    margin-bottom: 30px;
  }

  .news img {
    width: 100%;
    aspect-ratio: 2 / 1;
  }

  .news p:not(:last-child) {
    margin-bottom: 16px;
  }
}

.slider {
  padding: 3.75rem 0;
}

.slider__content {
  width: 100%;
  max-width: 595px;
  text-align: center;
  margin: 0 auto 3.125rem;
}

.slider__swiper {
  position: relative;
  overflow: hidden;
}

.slider__item {
  height: auto;
  position: relative;
}

.slider__list {
  padding-bottom: 250px;
}

.slider__desc {
  z-index: 1;
  width: 100%;
  max-width: 1080px;
  background-color: var(--color-white);
  margin: 0 auto;
  padding: 40px;
  position: absolute;
  left: 0;
  right: 0;
  translate: 0 -139px;
  box-shadow: 0 4px 100px #afadb51a;
}

.slider__nav .btn {
  position: absolute;
  top: 28%;
  transform: translateY(-50%);
}

.slider__nav .btn.swiper-button-disabled {
  opacity: .2;
  pointer-events: none;
}

.slider__nav .btn--prev {
  left: 30px;
  scale: -1 1;
}

.slider__nav .btn--next {
  left: auto;
  right: 30px;
}

.slider h1 {
  margin-bottom: 20px;
}

.slider h2 {
  margin-bottom: 16px;
  font-size: 26px;
}

.slider span {
  margin-bottom: 10px;
  display: block;
}

@media only screen and (max-width: 992px) {
  .slider {
    padding: 30px 0;
  }

  .slider__content {
    margin-bottom: 30px;
  }

  .slider__desc {
    padding: 16px;
  }

  .slider__list {
    padding-bottom: 15px;
  }

  .slider__desc {
    max-width: 100%;
    position: relative;
    inset: 0;
    translate: 0;
  }

  .slider img {
    margin-bottom: 24px;
  }
}

@media only screen and (max-width: 768px) {
  .slider .btn {
    top: 15%;
  }
}

@media only screen and (max-width: 576px) {
  .slider .btn {
    top: 10%;
  }
}

.inspiring {
  padding: 2.3125rem 0 3.75rem;
}

.inspiring__content {
  margin-bottom: 2.5rem;
}

.inspiring__swiper {
  gap: 20px;
  display: flex;
}

.inspiring__full {
  width: 100%;
  max-width: 610px;
  overflow: hidden;
}

.inspiring__full .inspiring__list {
  height: auto;
}

.inspiring__full .inspiring__item {
  z-index: 1;
  max-height: 565px;
  position: relative;
}

.inspiring__full .inspiring__item:before {
  content: "";
  background: linear-gradient(#15141100 34.487%, #151411 166.085%);
  position: absolute;
  inset: 0;
}

.inspiring__full .inspiring__desc {
  z-index: 1;
  color: var(--color-white);
  padding: 0 27px 27px;
  position: absolute;
  bottom: 0;
}

.inspiring__full h3 {
  color: var(--color-white);
}

.inspiring__full span, .inspiring__full p {
  opacity: .7;
}

.inspiring__thumbs {
  height: 100%;
  max-height: 843px;
  overflow-x: hidden;
  overflow-y: scroll;
}

.inspiring__thumbs .inspiring__item {
  max-height: 260px;
  cursor: pointer;
  align-items: center;
  gap: 26px;
  display: flex;
  height: 100% !important;
}

.inspiring__thumbs .inspiring__desc {
  translate: 0 8px;
}

.inspiring__thumbs .inspiring__desc span {
  margin-bottom: 14px;
}

.inspiring__thumbs .inspiring__desc h3 {
  margin-bottom: 14px;
  font-size: 24px;
}

.inspiring__thumbs .inspiring__desc p {
  font-size: 17px;
}

.inspiring__thumbs img {
  aspect-ratio: 1 / 1;
}

.inspiring__desc span {
  margin-bottom: 10px;
  display: block;
}

.inspiring__desc h3 {
  margin-bottom: 6px;
}

.inspiring__desc p {
  margin-bottom: 14px;
}

.inspiring__desc a {
  font-size: 18px;
  -webkit-text-decoration: underline #0000;
  text-decoration: underline #0000;
  transition: -webkit-text-decoration-color .4s linear, text-decoration-color .4s linear;
}

.inspiring__desc a:hover {
  -webkit-text-decoration-color: currentColor;
  text-decoration-color: currentColor;
}

.inspiring__nav {
  justify-content: end;
  display: flex;
}

.inspiring__nav .btn.swiper-button-disabled {
  background-color: var(--color-white);
  pointer-events: none;
}

.inspiring__nav .btn.swiper-button-disabled:before {
  background-color: var(--color-green-400);
  opacity: .2;
}

.inspiring__nav .btn--next {
  scale: -1 1;
}

.inspiring h2 {
  margin-bottom: 2.5rem;
}

@media only screen and (max-width: 1180px) {
  .inspiring__swiper {
    flex-direction: column;
  }

  .inspiring__full {
    max-width: 100%;
  }

  .inspiring__full .inspiring__item {
    overflow: hidden;
  }

  .inspiring__full img {
    width: 100%;
  }

  .inspiring__thumbs .inspiring__desc {
    display: none;
  }

  .inspiring__thumbs .inspiring__item img {
    width: 100%;
  }

  .inspiring__thumbs .inspiring__item:not(:last-child) {
    margin-bottom: 0;
  }
}

@media only screen and (max-width: 992px) {
  .inspiring {
    padding: 30px 0;
  }

  .inspiring h2 {
    margin-bottom: 20px;
  }
}

.reviews__content {
  width: 100%;
  max-width: 660px;
  text-align: center;
  margin: 0 auto 3.75rem;
}

.reviews__list {
  grid-template-columns: repeat(3, 1fr);
  gap: 20px;
  display: grid;
}

.reviews__item {
  flex-direction: column;
  padding: 24px;
  display: flex;
  box-shadow: 0 4px 100px #afadb51a;
}

.reviews__item:before {
  content: "";
  width: 40px;
  height: 40px;
  background-image: url("../images/icons/quote.svg");
  background-position: 0 0;
  background-repeat: no-repeat;
  background-size: cover;
  margin-bottom: 24px;
  display: block;
  position: relative;
}

.reviews__desc {
  justify-content: space-between;
  align-items: center;
  margin-top: auto;
  display: flex;
}

.reviews__author {
  align-items: center;
  gap: 14px;
  display: flex;
}

.reviews__author img {
  border-radius: 50%;
  overflow: hidden;
}

.reviews__rating {
  font-weight: var(--fw-700);
  color: var(--color-black);
  align-items: center;
  gap: 10px;
  line-height: 1.33;
  display: flex;
}

.reviews__rating:before {
  content: "";
  width: 24px;
  height: 24px;
  background-image: url("../images/icons/star.svg");
  background-position: 0 0;
  background-repeat: no-repeat;
  background-size: cover;
  display: block;
  position: relative;
}

.reviews p {
  margin-bottom: 24px;
}

.reviews h3 {
  font-size: 20px;
}

@media only screen and (max-width: 992px) {
  .reviews__list {
    grid-template-columns: repeat(2, 1fr);
  }

  .reviews__content {
    margin-bottom: 30px;
  }

  .reviews__item {
    padding: 16px;
  }

  .reviews__item:before {
    margin-bottom: 16px;
  }

  .reviews__item:last-child {
    grid-column: 2 span;
  }
}

@media only screen and (max-width: 768px) {
  .reviews__list {
    grid-template-columns: 1fr;
  }

  .reviews__item:last-child {
    grid-column: auto;
  }
}

.elevating__content {
  width: 100%;
  max-width: 656px;
  text-align: center;
  margin: 0 auto 3.75rem;
}

.elevating__swiper {
  position: relative;
  overflow: hidden;
}

.elevating__nav .btn {
  position: absolute;
  top: 35%;
  transform: translateY(-50%);
}

.elevating__nav .btn.swiper-button-disabled {
  opacity: .2;
  pointer-events: none;
}

.elevating__nav .btn--prev {
  left: 30px;
  scale: -1 1;
}

.elevating__nav .btn--next {
  left: auto;
  right: 30px;
}

@media only screen and (max-width: 992px) {
  .elevating__content {
    margin-bottom: 30px;
  }
}

.portfolio__wrapper {
  grid-template-columns: repeat(2, 1fr);
  align-items: flex-end;
  gap: 20px;
  display: grid;
}

.portfolio__content {
  width: 100%;
  max-width: 580px;
  margin-bottom: 3.75rem;
}

.portfolio__list {
  grid-template-columns: repeat(3, auto);
  justify-content: flex-end;
  gap: 52px;
  margin-bottom: 3.75rem;
  display: grid;
}

.portfolio__item span {
  font-weight: var(--fw-700);
  color: var(--color-black);
  margin-bottom: 10px;
  font-size: clamp(2rem, 1.75rem + 1.1111vi, 2.75rem);
  line-height: 1.3;
  display: block;
}

.portfolio h1 {
  margin-bottom: 3.75rem;
}

@media only screen and (max-width: 992px) {
  .portfolio__wrapper {
    grid-template-columns: 1fr;
  }

  .portfolio__content {
    max-width: 100%;
    margin-bottom: 30px;
  }

  .portfolio__list {
    justify-content: center;
    gap: 20px;
    margin-bottom: 30px;
  }

  .portfolio img {
    width: 100%;
    aspect-ratio: 2 / 1;
  }

  .portfolio h1 {
    margin-bottom: 30px;
  }
}

@media only screen and (max-width: 768px) {
  .portfolio__item {
    text-align: center;
  }
}

.service__content {
  margin-bottom: 30px;
}

.service__content .slogan {
  margin-bottom: 10px;
}

.service__list {
  grid-template-columns: repeat(3, 1fr);
  gap: 20px;
  display: grid;
}

.service__item .cards__content {
  padding-right: 70px;
}

@media only screen and (max-width: 992px) {
  .service__list {
    grid-template-columns: repeat(2, 1fr);
  }

  .service__item .cards__content {
    padding-right: 0;
  }

  .service__item:last-child {
    grid-column: 2 span;
  }

  .service__item:last-child img {
    width: 100%;
    aspect-ratio: 2 / 1;
  }
}

@media only screen and (max-width: 768px) {
  .service__list {
    grid-template-columns: 1fr;
  }

  .service__item img {
    width: 100%;
    aspect-ratio: 2 / 1;
  }

  .service__item:last-child {
    grid-column: auto;
  }
}

.furniture {
  padding: 2.8125rem 0 4.5rem;
}

.furniture__content {
  justify-content: space-between;
  align-items: center;
  margin-bottom: 2.6875rem;
  display: flex;
}

.furniture__content h2 {
  margin-bottom: 0;
}

.furniture__content p {
  width: 100%;
  max-width: 400px;
}

.furniture__list {
  grid-template-columns: repeat(2, auto);
  gap: 30px 20px;
  display: grid;
}

.furniture__item {
  z-index: 1;
  width: 100%;
  position: relative;
}

.furniture__item:before {
  content: "";
  z-index: 1;
  background: linear-gradient(#15141100 35.378%, #151411 137.5%);
  position: absolute;
  inset: 0;
}

.furniture__item:first-child {
  max-width: 505px;
  grid-row: 2 span;
}

.furniture__text, .furniture h3 {
  z-index: 1;
  color: var(--color-white);
  position: relative;
}

.furniture h3 {
  margin-bottom: 6px;
}

.furniture__text {
  padding: 0 31px 30px;
  position: absolute;
  bottom: 0;
}

@media only screen and (max-width: 992px) {
  .furniture {
    padding: 30px 0;
  }

  .furniture__content {
    margin-bottom: 30px;
  }

  .furniture__text {
    padding: 16px;
  }
}

@media only screen and (max-width: 768px) {
  .furniture__content {
    flex-direction: column;
    align-items: flex-start;
  }

  .furniture__content p {
    max-width: 100%;
  }

  .furniture__list {
    grid-template-columns: 1fr;
  }

  .furniture__item:first-child {
    max-width: 100%;
    grid-row: auto;
  }

  .furniture__item img {
    width: 100%;
    aspect-ratio: 2 / 1;
  }

  .furniture h2 {
    margin-bottom: 30px;
  }
}

/*# sourceMappingURL=main.css.map */
