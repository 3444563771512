/* stylelint-disable alpha-value-notation */
/* stylelint-disable color-function-notation */
@import url("https://fonts.googleapis.com/css2?family=Raleway:ital,wght@0,100..900;1,100..900&display=swap");

:root {
  --content-width: 1240px;
  --container-offset: 16px;
  --container-width: calc(var(--content-width) + (var(--container-offset) * 2));

  // font-family
  --font-family-primary: "Raleway", sans-serif;

  // font-weight
  --fw-300: 300;
  --fw-400: 400;
  --fw-500: 500;
  --fw-600: 600;
  --fw-700: 700;
  --fw-800: 800;

  //
  --color-white: #fff;
  --color-black: #151411;
  --color-grey-10: #F9F9F9;
  --color-grey-200: #f5f5f5;
  --color-grey-400: #afadb5;
  --color-green-50: #E6FFE4;
   --color-green-400: #518581;
   --color-green-200: #71C285;
  --color-green-900: #043a2a;
  --color-orange-400: #ffb23f;
  --color-red-400: #802F34;
  --color-blue-400: #B0D3F0;

  //  radius
  --radius-main: 8px;
}
