@use "../utils/functions/" as *;
@use "../utils/mixins/" as *;

.contacts {
  &__wrapper {
    position: relative;
  }

  &__content {
    position: absolute;
    left: 0;
    right: 0;
    top: 30px;
    margin: 0 auto;
    width: 100%;
    max-width: 600px;
  }

  &__form {
    width: 100%;
    gap: 24px;

    label,
    textarea {
      width: 100%;
    }

    input,
    textarea {
      border: 2px solid #ece4de;
      padding: 9px 0 9px 24px;
      width: 100%;
      max-width: 100%;
      background-color: var(--color-white);

      &::placeholder {
        font-size: 18px;
        line-height: 1.44;
        color: var(--color-grey-400);
      }
    }

    textarea {
      min-height: 110px;
      resize: none;
    }
  }

  &__inputs {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    margin-bottom: 20px;
    gap: 10px;
  }

  &__box {
    display: grid;
  }

  h2 {
    font-size: clamp(1.875rem, 1.625rem + 1.1111vi, 2.625rem);
    line-height: 1.24;
    letter-spacing: -0.01em;
    text-align: center;
    color: var(--color-white);
  }

  .btn {
    margin: 0 auto;
  }

  @include media(mobile-l) {
    &__wrapper {
      display: flex;
      flex-direction: column-reverse;
      gap: 25px;
    }

    &__content {
      position: relative;
      inset: 0;
    }

    h2 {
      color: var(--color-black);
    }
  }

  @include media(mobile-m) {
    &__inputs {
      grid-template-columns: 1fr;
      gap: 20px;

      .btn {
        max-width: 100%;
      }
    }

    &__box {
      gap: 20px;
    }
  }

  @include media(mobile-sm) {
    padding-top: 10px;
  }
}
