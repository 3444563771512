@use "../utils/functions/" as *;
@use "../utils/mixins/" as *;

h1,
h2,
h3 {
  overflow: hidden;
  font-weight: var(--fw-700);
  font-size: clamp(2.125rem, 1.5rem + 2.7778vi, 4rem);
  line-height: 1.3;
  color: var(--color-black);
}

h2 {
  margin-bottom: 30px;
  font-size: clamp(1.875rem, 1.5833rem + 1.2963vi, 2.75rem);
}

h3 {
  font-size: clamp(1.125rem, 0.9583rem + 0.7407vi, 1.625rem);
}

p {
  font-size: 18px;

  @include media(mobile-m) {
    font-size: 16px;
  }
}

.slogan {
  display: block;
  margin-bottom: 14px;
  font-weight: var(--fw-700);
  font-size: 18px;
  line-height: 1.3;
  color: var(--color-orange-400);
}
