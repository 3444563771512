/* stylelint-disable declaration-no-important */
@use "../utils/functions/" as *;
@use "../utils/mixins" as *;

*[class].btn {
  display: flex;
  align-items: center;
  justify-content: center;
  border: 2px solid transparent;
  border-radius: 0;
  padding: 13px 50px;
  width: 100%;
  height: max-content;
  max-width: fit-content;
  font-weight: var(--fw-700);
  font-size: 18px;
  line-height: 1.3;
  color: var(--color-white);
  background-color: var(--color-green-400);
  transition-property: background-color, color, border-color;
  transition-duration: 0.3s;

  &:hover,
  &:focus {
    border-color: var(--color-green-400);
    color: var(--color-green-400);
    background-color: var(--color-white);
  }

  &.formsapp-button {
    margin: 0;
    font-family: var(--font-family-primary) !important;
  }

  &--reverse {
    border-color: var(--color-green-400);
    color: var(--color-green-400);
    background-color: var(--color-white);

    &:hover,
    &:focus {
      color: var(--color-white);
      background-color: var(--color-green-400);
    }
  }

  &--filter {
    border-color: transparent;
    padding: 15px 20px;
    white-space: nowrap;
    color: var(--color-grey-400);
    background-color: transparent;

    &:hover,
    &:focus {
      border-color: transparent;
      color: var(--color-black);
      background-color: var(--color-grey-10);
    }

    &.active {
      border-color: transparent;
      box-shadow: 0 4px 120px 0 rgba(175, 173, 181, 0.15);
      color: var(--color-black);
      background-color: var(--color-grey-10);
    }
  }

  &--request {
    color: var(--color-white) !important;
    background-color: var(--color-green-400) !important;

    &:hover,
    &:focus {
      border-color: var(--color-green-400) !important;
      color: var(--color-green-400) !important;
      background-color: var(--color-white) !important;
    }
  }

  &--elevating,
  &--slider {
    position: relative;
    z-index: 2;
    border-color: transparent;
    border-radius: 50%;
    padding: 10px;
    height: 52px;
    max-width: 52px;
    background-color: rgba(21, 20, 17, 0.6);
    backdrop-filter: blur(15px);

    &::before {
      content: "";
      position: absolute;
      width: 24px;
      height: 24px;
      background-image: url("../images/icons/chevron.svg");
      background-position: 0 0;
      background-size: cover;
      background-repeat: no-repeat;
    }

    &:hover,
    &:focus {
      border-color: transparent;
      background-color: transparent;

      &::before {
        opacity: 1;
      }
    }

    @include media(mobile-l) {
      height: 42px;
      max-width: 42px;
    }
  }

  &--inspiring {
    padding: 32px;
    box-shadow: 0 4px 90px 0 rgba(175, 173, 181, 0.2);
    background-color: var(--color-green-400);

    &::before {
      @include mask;

      mask-image: url("../images/icons/arrow.svg");
      width: 28px;
      height: 28px;
      background-color: var(--color-white);
    }

    &:hover,
    &:focus {
      background-color: var(--color-white);

      &::before {
        background-color: var(--color-green-400);
      }
    }

    @include media(mobile-l) {
      padding: 20px;
    }
  }
}
