@use "../../utils/functions/" as *;
@use "../../utils/mixins/" as *;

.furniture {
  padding: rem(45) 0 rem(72);

  &__content {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: rem(43);

    h2 {
      margin-bottom: 0;
    }

    p {
      width: 100%;
      max-width: 400px;
    }
  }

  &__list {
    display: grid;
    grid-template-columns: repeat(2, auto);
    gap: 30px 20px;
  }

  &__item {
    position: relative;
    z-index: 1;
    width: 100%;

    &::before {
      content: "";
      position: absolute;
      z-index: 1;
      background: linear-gradient(180deg, rgba(21, 20, 17, 0) 35.378%, rgb(21, 20, 17) 137.5%);
      inset: 0;
    }

    &:first-child {
      grid-row: 2 span;
      max-width: 505px;
    }
  }

  &__text,
  h3 {
    position: relative;
    z-index: 1;
    color: var(--color-white);
  }

  h3 {
    margin-bottom: 6px;
  }

  &__text {
    position: absolute;
    bottom: 0;
    padding: 0 31px 30px;
  }

  @include media(tablet) {
    padding: 30px 0;

    &__content {
      margin-bottom: 30px;
    }

    &__text {
      padding: 16px;
    }
  }

  @include media(mobile-l) {
    &__content {
      flex-direction: column;
      align-items: flex-start;

      p {
        max-width: 100%;
      }
    }

    &__list {
      grid-template-columns: 1fr;
    }

    &__item {
      &:first-child {
        grid-row: auto;
        max-width: 100%;
      }

      img {
        width: 100%;
        aspect-ratio: 2/1;
      }
    }

    h2 {
      margin-bottom: 30px;
    }
  }
}
